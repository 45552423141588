import React, { useEffect, useState } from "react";
import AOS from "aos";

import { CONTACT } from "../config/urls";
import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import GetInTouchSection from "../components/GetInTouchSection";
import { HelmetProvider } from "react-helmet-async";

import {
  HeroBackground,
  HeroContainer,
  InternalWrapper,
  HeroPictureContainer,
  HeroPicture,
  TextDiv,
  Title,
  IntroductionDiv,
  TeamContainer,
  TeamDescription,
  TeamCardContainer,
  TeamCard,
  TeamPic,
  TeamInfo,
  TeamName,
  TeamTitle,
  TeamSectionTitle,
  TeamSectionSubtitle,
} from "../styles/pages/meet-our-team.jsx";

import HeroPNG from "../images/meet-our-team/jpg/team.png";

import dovAvif from "../images/meet-our-team/avif/dov.avif";
import michelleAvif from "../images/meet-our-team/avif/michelle.avif";
import mariaAvif from "../images/meet-our-team/avif/maria.avif";
import jackieAvif from "../images/meet-our-team/avif/jackie.avif";
import isabelAvif from "../images/meet-our-team/avif/isabel.avif";
import bianidaAvif from "../images/meet-our-team/avif/bianida.avif";
import davidAvif from "../images/meet-our-team/avif/david.avif";
import juniorAvif from "../images/meet-our-team/avif/junior.avif";
import kaitlynAvif from "../images/meet-our-team/avif/kaitlyn.avif";
import GiovannaAvif from "../images/meet-our-team/avif/giovanna.avif";

import dovJpg from "../images/meet-our-team/jpg/dov.jpg";
import michelleJpg from "../images/meet-our-team/jpg/michelle.jpg";
import mariaJpg from "../images/meet-our-team/jpg/maria.jpg";
import jackieJpg from "../images/meet-our-team/jpg/jackie.jpg";
import isabelJpg from "../images/meet-our-team/jpg/isabel.jpg";
import bianidaJpg from "../images/meet-our-team/jpg/bianida.jpg";
import davidJpg from "../images/meet-our-team/jpg/david.jpg";
import juniorJpg from "../images/meet-our-team/jpg/junior.jpg";
import kaitlynJpg from "../images/meet-our-team/jpg/kaitlyn.jpg";
import GiovannaJpg from "../images/meet-our-team/jpg/giovanna.png";

const team = [
  {
    name: "Dov Brafman, NREMT",
    position: "CEO",
    alt: "Dov Brafman, NREMT CEO",
    avif: dovAvif,
    jpg: dovJpg,
    description:
      "I started Life Ride in 2018 in order for seniors and individuals with special needs to get to medical and social appointments in the community.  Our vision at life is to offer all of the services that would allow our clients to age in place in the comfort of their own homes. I enjoy spending time with my family- especially outdoors- and volunteering as an EMT.",
  },
  {
    name: "Michelle Werner, MSW, LSW, CDP",
    position: "Vice President and Co-founder, Home Care",
    alt:
      "Michelle Werner, MSW, LSW, CDP Vice President and Co-founder, Home Care",
    avif: michelleAvif,
    jpg: michelleJpg,
    description:
      "I have worked as a Licensed Social Worker for over 20 years, primarily working in the field of home care.  I am passionate about advocating for clients to age safely in their own homes. I love spending time with my husband, Rob, and children, Danny and Renata. My dog, Junior, comes to Life Home Care with me and takes his position as Head of Security very seriously!",
  },
  {
    name: "María Andrew, CDP",
    position: "Director of Community Relations",
    alt: "María Andrew, CDP Vice President of Business Development",
    avif: mariaAvif,
    jpg: mariaJpg,
    description:
      "As Director of Community Relations, I bring over 19 years of home care experience to my role at Life Homecare. My passion started in 2004 as a staffing manager. My experience has allowed me to understand the emotional distress families go through, and I am here to help. In my free time, I love roller skating, paddle boarding, and walking my Havanese, Siggy.",
  },
  {
    name: "Jackie Erickson, RN, BSN",
    position: "Director of Nursing- Livingston",
    alt: "Jackie Erickson, RN, BSN Director of Nursing- Livingston",
    avif: jackieAvif,
    jpg: jackieJpg,
    description:
      "My nursing career started in 1994 after earning my BSN at Rutgers University.  I’ve worked in multiple settings but I found my true passion working in home care! In my free time, I enjoy spending time with friends and family, traveling, taking walks with my beloved dog Mandie, and cheering on my 3 children who play soccer, basketball and hockey.",
  },
  {
    name: "Giovanna Marte, RN, BSN",
    position: "Director of Nursing, Glen Rock",
    alt: "Giovanna Marte, RN, BSN Director of Nursing, Glen Rock",
    avif: GiovannaAvif,
    jpg: GiovannaJpg,
    description:
      "I have been a Registered Nurse for 9 years primarily working with the geriatric population. I truly love working in the field of home care, as I feel that our clients and families value our efforts to keep them safe and comfortable in their familiar surroundings. I also enjoy reading and outdoor activities with family and friends.",
  },
  {
    name: "Isabel Osorio",
    position: "Senior Director of Human Resources",
    alt: "Isabel Osorio Senior Director of Human Resources",
    avif: isabelAvif,
    jpg: isabelJpg,
    description:
      "I have been working in the healthcare field for 8 years.  I enjoy finding the most skilled and caring staff to work with our clients, as well as interacting with our clients and families.  I love spending time with my husband, son, two cats, and dog. I also love karaoke.",
  },
  {
    name: "Bianida Reyes",
    position: "Intake Coordinator",
    alt: "Bianida Reyes Intake Coordinator",
    avif: bianidaAvif,
    jpg: bianidaJpg,
    description:
      "I have been working in  the home care field for 5 years and I work hard to coordinate the best match between our caregivers and clients. I am continuing my education in the social work field so that I can further assist our clients and their families with their overall psychosocial needs. In my free time, I love to play volleyball and go to the beach.",
  },
  {
    name: "David Teitelbaum",
    position: "Vice President of Finance",
    alt: "David Teitelbaum Vice President of Finance",
    avif: davidAvif,
    jpg: davidJpg,
    description:
      "As Vice President of Finance, I support the company through handling multiple financial decisions. In addition, I assist our clients by being the key billing director. In my free time, I enjoy my pets including my cat, Dalia, our Junior, and my 7 snapping turtles.",
  },
  {
    name: "Kaitlyn Kelly, RN, BSN",
    position: "Nurse Supervisor",
    alt: "Kaitlyn Kelly, RN, BSN Nurse Supervisor",
    avif: kaitlynAvif,
    jpg: kaitlynJpg,
    description:
      "I love being able to connect with my clients and their families in their homes, where we are able to provide them with care personalized to their own needs. In my free time, I enjoy going to the gym and playing soccer.",
  },
  {
    name: "Junior",
    position: "Security",
    alt: "Junior Dog Security",
    avif: juniorAvif,
    jpg: juniorJpg,
    description:
      "I belong to Michelle, but I watch over all of our  staff diligently!  I love to bark at everyone coming into the office, and snuggle with the team to make them smile during their work day!",
  },
];

const MeetOurTeam = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(
    () => {
      AOS.init({
        // initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <HelmetProvider key={isClient ? "client" : "server"}>
      <Layout hasTransparentTopBar>
        <Seoinfo
          siteName="Life Health Group"
          titlePage="Meet Our Team | Life Home Care"
          description="Explore the profiles of our committed team members who drive our company forward. Meet the individuals shaping our future!"
          keywords="Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"
          type="website"
          url="/meet-our-team"
          imageFacebook="/seo/seo_image.png"
          canonical
        />
        <HeroBackground>
          <HeroContainer>
            <HeroPictureContainer>
              <HeroPicture>
                <img
                  srcSet={HeroPNG}
                  alt="Life Home Care Team"
                  type="image/jpg"
                />
              </HeroPicture>
            </HeroPictureContainer>
            <InternalWrapper>
              <TextDiv data-aos="fade">
                <Title>
                  MEET <span> THE TEAM </span>
                </Title>
                <IntroductionDiv>
                  Meet some of our dedicated staff members!
                </IntroductionDiv>
              </TextDiv>
            </InternalWrapper>
          </HeroContainer>
        </HeroBackground>
        <TeamSectionTitle>Learn more about the team!</TeamSectionTitle>
        <TeamSectionSubtitle>
          Find out more about our team members by hovering over their pictures!
        </TeamSectionSubtitle>
        <TeamContainer>
          {team.map((member, i) => (
            <TeamCardContainer>
              <TeamCard index={i}>
                <TeamPic id={`team-pic-${i}`}>
                  <source srcSet={member.avif} type="image/avif" />
                  <img src={member.jpg} alt={member.alt} type="image/jpg" />
                </TeamPic>
                <TeamInfo id={`team-info-${i}`}>
                  <TeamName>{member.name}</TeamName>
                  <TeamTitle>{member.position}</TeamTitle>
                  <TeamDescription id={`team-description-${i}`}>
                    {member.description}
                  </TeamDescription>
                </TeamInfo>
              </TeamCard>
            </TeamCardContainer>
          ))}
        </TeamContainer>
        <GetInTouchSection contactEmail={CONTACT.MAIL} />
      </Layout>
    </HelmetProvider>
  );
};

export default MeetOurTeam;
